<template lang="pug">
  .publish-settings
    .container-fluid
      .row
        PlanStatus(:status="currentStatus")
      .row
        .toggle
          .tooltip-label
            FormFieldLabel(:title="$t('ota_management.plan_list.form.publish_settings.status')")
            AppTooltip(
              useAppIcon
              :title="$t('ota_management.company_list.tooltips.auto_publish')"
              icon="exclamationCircleAlt"
            )
            span.required {{ $t('ota_management.required') }}
          AppToggle(
            :disabled="isReleaseToggleDisabled"
            v-model="item.released"
          )
      .row
        .immutable-field
          FormFieldLabel.label(:title="$t('ota_management.plan_list.form.publish_settings.plan_code')")
          span {{ getProp("plan_code") }}
      .row
        .immutable-field
          FormFieldLabel.label(:title="$t('ota_management.plan_list.form.publish_settings.plan_name')")
          span {{ getProp("plan_name") }}
      .row
        .immutable-field
          .period-label
            AppIcon(name="calendar")
            FormFieldLabel.label(:title="$t('ota_management.plan_list.form.publish_settings.sales_period')")
          span {{ getProp("sales_period") }}
      .row
        .immutable-field
          FormFieldLabel.label(:title="$t('ota_management.plan_list.form.publish_settings.available_week_days')")
          span {{ weekDays }}
      .row
        .immutable-field
          .period-label
            AppIcon(name="calendar")
            FormFieldLabel.label(:title="$t('ota_management.plan_list.form.publish_settings.posting_period')")
          span {{ getProp("posting_period") }}
      .row
        .immutable-field
          FormFieldLabel.label(:title="$t('ota_management.plan_list.form.publish_settings.price_list.time_slot')")
          span {{ getProp("time_slot") }}
      .row.rates
        FormFieldLabel.label(:title="$t('ota_management.plan_list.form.publish_settings.basic_rates')")
        .rates-bar
          PriceListItem(
            v-for="(rate, index) in basicRates"
            :key="rate.id"
            :item="rate"
            @change-markup-fee="changeBasicRateMarkupFee(index, $event)"
          )
      template(v-for="(specialRate, specialRateIndex) in specialRates")
        .row.rates
          .rate-number
            FormFieldLabel.label(:title="$t('ota_management.plan_list.form.publish_settings.special_rate', { number: specialRateIndex + 1 })")
          .rate-period
            FormFieldLabel.label(:title="$t('ota_management.plan_list.form.publish_settings.special_rate_period')")
            span {{ specialRate.period }}
          .rates-bar
            PriceListItem(
              v-for="(rate, index) in specialRate.rates"
              :key="rate.id"
              :item="rate"
              @change-markup-fee="changeSpecialRateMarkupFee(specialRateIndex, index, $event)"
            )
      .row
        .immutable-field
          FormFieldLabel.label(:title="$t('ota_management.plan_list.form.publish_settings.plan_sales_shop')")
          span {{ getProp("plan_sales_shop") }}
    .save-action-row
      AppButton(
        @click="$emit('update-markups')"
        title="general_settings.save"
      )
</template>

<script>
  // misc
  import { PERCENT_MARKUP } from "@/config/constants"
  import IMask from "imask"
  import { get, map, toString } from "lodash-es"

  // mixins
  import withModal from "@/mixins/withModal"

  export default {
    components: {
      PriceListItem: () => import("./PriceListItem"),
      AppIcon: () => import("@/components/elements/AppIcon"),
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      PlanStatus: () => import("@/components/CompanySystem/PlanStatus"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [withModal],

    props: {
      item: {
        type: Object,
        default: () => {}
      }
    },

    data() {
      return {
        delimiterMask: IMask.createMask({ mask: Number, thousandsSeparator: "," })
      }
    },

    methods: {
      getProp(name) {
        return get(this.item, name)
      },

      changeBasicRateMarkupFee(rateItemIndex, newFee) {
        const newRateItem = this.buildNewRateItem(this.item.basic_rates[rateItemIndex], newFee)
        this.$emit("set-basic-rate-item", { index: rateItemIndex, item: newRateItem })
      },

      changeSpecialRateMarkupFee(specialRateIndex, rateItemIndex, newFee) {
        const rateItem = this.item.special_rates[specialRateIndex].rates[rateItemIndex]
        const newRateItem = this.buildNewRateItem(rateItem, newFee)
        this.$emit("set-special-rate-item", { specialRateIndex, index: rateItemIndex, item: newRateItem })
      },

      buildNewRateItem(oldRateItem, fee) {
        const oldFee = oldRateItem.markup_fee
        const newFee = { ...oldFee, ...fee }
        const actualFee = newFee.value > 0 ? newFee : this.companyMarkupFee

        return {
          ...oldRateItem,
          markup_fee: newFee,
          rate_list: this.buildRateList(actualFee, oldRateItem.rate_list)
        }
      },

      buildRateList(fee, rates) {
        return map(rates, rate => {
          return { ...rate, ...this.buildAfterMarkupFee(fee, rate.default_fee_value) }
        })
      },

      buildAfterMarkupFee(fee, defaultFeeValue) {
        const afterMarkupFee = Math.round(this.calculateAfterMarkup(fee, defaultFeeValue))
        const afterMarkupFeeLocal = ((afterMarkupFee / 100) * this.exchangeRate).toFixed(2)
        return {
          after_markup_local: `${afterMarkupFeeLocal} ${this.currencyLabel}`,
          after_markup_yen: `${this.formatValue(afterMarkupFee)} 円`
        }
      },

      calculateAfterMarkup(fee, value) {
        if (fee.type === PERCENT_MARKUP.id) {
          return value + (value * fee.value) / 100
        } else {
          return value + fee.value
        }
      },

      formatValue(value) {
        this.delimiterMask.resolve(toString(value))
        return this.delimiterMask.value
      }
    },

    computed: {
      currentStatus() {
        return get(this.item, "current_status", "")
      },

      isReleaseToggleDisabled() {
        return !this.item.releasable && !this.item.released
      },

      exchangeRate() {
        return get(this.item, "currency_data.exchange_rate", 0)
      },

      currencyLabel() {
        return get(this.item, "currency_data.currency_label", "")
      },

      companyMarkupFee() {
        return get(this.item, "company_markup", {})
      },

      availableWeekDays() {
        return get(this.item, "available_week_days", [])
      },

      basicRates() {
        return get(this.item, "basic_rates", [])
      },

      specialRates() {
        return get(this.item, "special_rates", [])
      },

      weekDays() {
        return this.availableWeekDays
          .map(day => this.$t(`company_system.rate_plan_settings.rate_plan.details.basic_settings.weekdays.${day}`))
          .join(", ")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .publish-settings
    overflow-y: auto
    max-width: 65vw

    .container-fluid
      +listing-container(55px)
      overflow-y: scroll

    .save-action-row
      display: flex
      justify-content: right
      margin: 20px 0

      button
        background: $default-purple
        color: $default-white

    .row
      margin-bottom: 30px
      font-size: 12.8px

      ::v-deep
        .label
          color: $default-gray

          span.label
            font-weight: 400
            color: $default-gray

        .action-button
          +icon-button($default-purple)

        .app-icon-wrapper
          svg
            height: 20px
            width: 20px

      &.markup-action-row
        display: flex
        flex-direction: column

        .markup-action
          width: 190px

      &.rates
        display: flex
        flex-direction: column

        .rate-number
          margin-bottom: 15px

        .rate-period
          display: flex
          flex-direction: column
          margin-bottom: 15px

      .rates-bar
        overflow-x: auto
        width: 100%
        padding: 40px 15px
        display: flex
        justify-content: left
        background-color: $default-gray-light

        .price-list-item
          margin-right: 15px

      .immutable-field
        display: flex
        flex-direction: column

        .period-label
          margin-bottom: 7px

          svg
            margin-right: 5px
            margin-bottom: 2px

      .toggle
        flex-direction: column
        margin-top: 7px

      .tooltip-label
        display: flex
        justify-content: left
        color: $default-gray
        margin-bottom: 10px

        .required
          margin-left: 10px
          font-size: 12px
          color: $default-red
</style>

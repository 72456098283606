<template lang="pug">
  .account-management
    AppOverlayLoader(:state="rolesLoading")
    PermissionSettingsTab(
      :roles="roles"
      :rolesLoading="rolesLoading"
      :create-role="createRole"
      :update-role="updateRole"
      :delete-role="deleteRole"
    )
</template>

<script>
  // store modules
  import rolesModule from "@/config/store/roles"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  const rolesMixin = withStoreModule(rolesModule, {
    name: "roles",
    readers: {
      roles: "items",
      rolesLoading: "loading"
    },
    actions: {
      fetchRoles: "FETCH_ITEMS",
      createRole: "CREATE_ITEM",
      updateRole: "UPDATE_ITEM",
      deleteRole: "DELETE_ITEM"
    }
  })

  export default {
    components: {
      PermissionSettingsTab: () => import("./PermissionSettingsTab"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [rolesMixin],

    created() {
      this.fetchRoles({ forceReload: false })
    }
  }
</script>

<style lang="sass" scoped>
  .account-management
    position: relative
</style>

<template lang="pug">
  .edit-account
    AppOverlayLoader(:state="loading")
    AccountForm(
      v-if="!loading && account"
      :key="account.id"
      :roles="roles"
      :roles-loading="rolesLoading"
      :user-account="userAccount"
      :save-action="updateUser"
      :resetable="false"
      :after-save="goToAccountsList"
      :form-title="$t('account_management.account_list.edit_account')"
    )
</template>

<script>
  // store modules
  import accountsModule from "@/config/store/accounts"
  import rolesModule from "@/config/store/roles"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withPermissions from "@/mixins/withPermissions"

  const accountsMixin = withStoreModule(accountsModule, {
    name: "accounts",
    readers: { account: "item", accountsLoading: "loading" },
    actions: { fetchUser: "FETCH_ITEM", updateUser: "UPDATE_ITEM" }
  })

  const rolesMixin = withStoreModule(rolesModule, {
    name: "roles",
    readers: { roles: "items", rolesLoading: "loading" },
    actions: { fetchRoles: "FETCH_ITEMS" }
  })

  export default {
    components: {
      AccountForm: () => import("@/components/AccountManagement/AccountForm"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [accountsMixin, rolesMixin, withPermissions],

    mounted() {
      if (!this.hasEditPermission() || this.isSuperadmin) {
        return this.$router.push({ name: "Forbidden" })
      }

      this.fetchUser(this.userId)
      this.fetchRoles({ forceReload: false })
    },

    computed: {
      userId() {
        return this.$route.params.id
      },

      loading({ accountsLoading, rolesLoading }) {
        return accountsLoading || rolesLoading
      },

      userAccount() {
        return { ...this.account, password: "", password_confirmation: "" }
      }
    },

    methods: {
      goToAccountsList() {
        return this.$router.push({ name: "AccountList" })
      }
    }
  }
</script>

<style lang="sass" scoped>
  .edit-account
    position: relative
</style>

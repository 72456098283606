<template lang="pug">
  .about-form
    AppOverlayLoader(:state="loading")
    .title
      span {{ $t("company_system.basic_settings.about.details") }}
    .form
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.basic_settings.about.company_name')")
        .form-row-field
          BFormInput(
            type="text"
            name="name"
            v-model="about.name"
            :placeholder="$t('company_system.basic_settings.about.company_name')"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.basic_settings.about.description')")
        .form-row-field
          textarea.form-control(
            v-model="about.description"
            type="text"
            :rows="3"
            :max-rows="10"
            :maxlength="1024"
            :placeholder="$t('company_system.basic_settings.about.description')"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.basic_settings.about.otas')")
        .form-row-field
          | {{ selectedOtaNames }}
      .form-row
        .form-row-label
          FormFieldLabel(
            :title="$t('company_system.basic_settings.about.logo_image', { number: 1 })"
            required
            :required-label="$t('company_system.req')"
          )
        .form-row-field
          img(
            v-if="about.medium_logo_url"
            :src="about.medium_logo_url"
          )
          BFormFile(
            v-else
            accept="image/jpeg, image/png, image/gif"
            name="image-file"
            :state="mediumLogoValidationState"
            @input="handleImageFileUpload('mediumLogo', $event)"
          )
        AppIconButton.delete(
          icon="trash-alt"
          title="company_system.delete"
          @click="handleImageFileDelete('mediumLogo')"
        )
        .form-row-label
          span {{ $t(`company_system.basic_settings.about.image_size`, { width: 200, height: 150 }) }}
      .form-row
        .form-row-label
          FormFieldLabel(
            :title="$t('company_system.basic_settings.about.logo_image', { number: 2 })"
            required
            :required-label="$t('company_system.req')"
          )
        .form-row-field
          img(
            v-if="about.small_logo_url"
            :src="about.small_logo_url"
          )
          BFormFile(
            v-else
            accept="image/jpeg, image/png, image/gif"
            name="image-file"
            :state="smallLogoValidationState"
            @input="handleImageFileUpload('smallLogo', $event)"
          )
        AppIconButton.delete(
          icon="trash-alt"
          title="company_system.delete"
          @click="handleImageFileDelete('smallLogo')"
        )
        .form-row-label
          span {{ $t(`company_system.basic_settings.about.image_size`, { width: 150, height: 30 }) }}
      .form-row
        .form-row-label
          FormFieldLabel(
            :title="$t('company_system.basic_settings.about.terms_and_conditions')"
            required
            :required-label="$t('company_system.req')"
          )
        .form-row-label.file-name(v-if="isTermsAndConditionsAttached")
          a(
            :href="about.terms_and_conditions.url"
            target="_blank"
          )
            | {{ about.terms_and_conditions.name }}
        .form-row-field(v-else)
          BFormFile(
            accept="application/pdf"
            name="image-file"
            :state="termsAndConditionsValidationState"
            @input="handleFileUpload($event)"
          )
        AppIconButton.delete(
          icon="trash-alt"
          title="company_system.delete"
          @click="handleFileDelete"
        )
        .form-row-label
          span {{ $t(`company_system.basic_settings.about.updated_at`, { updated_at: termsAndConditionsUpdatedAt }) }}
    FormActionBar(
      delete-enabled
      @save="handleSave"
    )
</template>

<script>
  // misc
  import { snakeCase, map, get } from "lodash-es"
  import { mapGetters } from "vuex"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withValidations from "@/mixins/withValidations"
  import withScrollTop from "@/mixins/withScrollTop"

  // stores
  import aboutModule from "@/config/store/company_system/basic_settings/about"

  import { OTAS_WITH_COMPANY_SYSTEM } from "@/config/constants"

  const companyAboutMixin = withStoreModule(aboutModule, {
    resetState: true,
    name: "companyAbout",
    readers: { about: "item", loading: "loading" },
    actions: {
      fetchAbout: "FETCH_ITEM",
      updateAbout: "UPDATE_ITEM"
    },
    mutations: { setAbout: "SET_ITEM" }
  })

  const validationsMixin = withValidations(({ required }) => ({
    about: {
      name: { required: false },
      description: { required: false },
      medium_logo_url: { required },
      small_logo_url: { required },
      terms_and_conditions: {
        url: { required }
      },
      ota_ids: { required: false }
    }
  }))

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      FormActionBar: () => import("../../FormActionBar"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [companyAboutMixin, validationsMixin, withScrollTop],

    data() {
      return {
        mediumLogo: null,
        smallLogo: null,
        largeLogo: null,
        termsAndConditions: null
      }
    },

    mounted() {
      this.fetchAbout()
    },

    methods: {
      textLength(length, maxLength) {
        return `(${length}/${maxLength})`
      },

      imageFileUrlKey(fileName) {
        return `${snakeCase(fileName)}_url`
      },

      handleImageFileUpload(fileName, file) {
        this[fileName] = file
        this.about[this.imageFileUrlKey(fileName)] = URL.createObjectURL(file)
      },

      handleFileUpload(file) {
        this.termsAndConditions = file
        this.about.terms_and_conditions = {
          name: file.name,
          url: URL.createObjectURL(file)
        }
      },

      handleImageFileDelete(fileName) {
        this[fileName] = null
        this.about[this.imageFileUrlKey(fileName)] = null
      },

      handleFileDelete() {
        this.termsAndConditions = null
        this.about.terms_and_conditions = {}
      },

      handleSave() {
        this.validateAttributes()
        if (!this.isValid) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".is-invalid", inline: "center" })
          })
          return
        }
        this.sendRequest().then(() => {
          this.cancelValidation()
        })
      },

      buildFormData() {
        const formData = new FormData()
        if (this.mediumLogo) {
          formData.append("medium_logo", this.mediumLogo)
        }
        if (this.smallLogo) {
          formData.append("small_logo", this.smallLogo)
        }
        if (this.termsAndConditions) {
          formData.append("terms_and_conditions", this.termsAndConditions)
        }
        formData.append("about", JSON.stringify(this.about))
        return formData
      },

      async sendRequest() {
        await this.updateAbout({ formData: this.buildFormData() })
      },

      setOtas(otas) {
        this.setAbout({ ...this.about, ota_ids: map(otas, "id") })
      }
    },

    computed: {
      ...mapGetters(["translatedOtaList"]),

      isTermsAndConditionsAttached() {
        return this.termsAndConditions || this.about.terms_and_conditions?.url
      },

      availableOtas({ translatedOtaList }) {
        return translatedOtaList.filter(({ rawName }) => OTAS_WITH_COMPANY_SYSTEM.includes(rawName))
      },

      selectedOtas({ availableOtas, about }) {
        return availableOtas.filter(({ id }) => (about.ota_ids || []).includes(id))
      },

      selectedOtaNames() {
        return map(this.selectedOtas, "name").join(", ")
      },

      mediumLogoValidationState() {
        return this.$v.about.medium_logo_url.$error ? false : null
      },

      smallLogoValidationState() {
        return this.$v.about.small_logo_url.$error ? false : null
      },

      termsAndConditionsValidationState() {
        return this.$v.about.terms_and_conditions.url.$error ? false : null
      },

      termsAndConditionsUpdatedAt() {
        return get(this.about, "terms_and_conditions.updated_at", "-")
      },

      descriptionLength() {
        return this.about.description?.length || 0
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/variables.sass"

  .about-form
    position: relative
    +styled-inputs

    input
      height: 38px

    margin-bottom: 20px

    .flex-row
      display: flex
      justify-content: left


    .title
      margin-top: 20px
      height: 40px
      border-left: 13px solid $default-purple-light
      padding: 10px
      font-weight: 700
      font-size: 16px

    .form
      padding: 5px

      &-row
        display: flex
        flex-direction: column
        margin-top: 20px

        button.delete
          margin-top: 10px
          width: 120px
          border-color: $default-red !important

          ::v-deep
            .app-button-content
              color: $default-red

        &-label
          font-style: normal
          font-weight: 200
          font-size: 13px
          line-height: 19px

          &.file-name
            font-size: 18px
            font-weight: 400
            margin-top: 10px

          a
            color: $default-gray

          span
            &.required
              margin-left: 10px
              font-size: 12px
              color: $default-red

          &.left
            margin-top: 8px
            margin-right: 10px

          &.right
            margin-top: 8px
            margin-left: 10px

        &-field
          margin-top: 5px
          font-weight: 400
          font-size: 16px

          img
            width: 300px

          input
            &.rental-time
              width: 40px

          .text-length
            font-size: 12px
            color: $default-gray-medium
          .otas
            padding: 0
</style>

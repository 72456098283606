<template lang="pug">
  .booking-confirmation-tab
    AppOverlayLoader(:state="reservationsLoading")
    .booking-confirmation-form(
      v-if="selectedReservation"
    )
      Form(
        :reservationId="selectedReservation.id"
        :statuses="statuses"
        @unselect-booking="selectReservation(null)"
      )
    .booking-confirmation-table(
      v-if="!selectedReservation"
    )
      Filters(
        :filters="filters"
        :shops="shops"
        :statuses="statuses"
        @change-filters="changeFilters"
      )
      Table(
        :items="reservations"
        :sorting-data="sorting"
        :statuses="statusesObject"
        @sorting="changeSorting"
        @select-booking="selectReservation"
      )
      AppPagination(
        :current-page="pagination.current_page"
        :total="pagination.total_count"
        :per-page="pagination.per_page"
        @change-pagination-data="changePagination"
      )
</template>

<script>
  import { appDebounce } from "@/helpers/common"

  // store modules
  import bookingConfirmationModule from "@/config/store/company_system/booking_confirmation"
  import shopsModule from "@/config/store/company_system/shops_settings/shops/basic_settings"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import { reduce } from "lodash-es"

  const bookingConfirmationMixin = withStoreModule(bookingConfirmationModule, {
    name: "bookingConfirmationMixin",
    readers: {
      reservations: "items",
      reservationsLoading: "loading",
      sorting: "sorting",
      pagination: "pagination",
      filters: "filters"
    },
    mutations: {
      setPagination: "SET_PAGINATION_DATA",
      setFilters: "SET_FILTERS",
      setSorting: "SET_SORTING"
    },
    actions: {
      fetchReservations: "FETCH_ITEMS"
    }
  })

  const shopsMixin = withStoreModule(shopsModule, {
    resetState: true,
    name: "companiesShops",
    readers: { shops: "items" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  export default {
    components: {
      Filters: () => import("./Filters"),
      Form: () => import("./Form"),
      Table: () => import("./Table"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppPagination: () => import("@/components/elements/AppPagination")
    },

    mixins: [bookingConfirmationMixin, shopsMixin],

    data() {
      return {
        selectedReservation: null
      }
    },

    created() {
      this.debouncedFetchReservations = appDebounce(() => this.fetchReservations())
    },

    mounted() {
      this.fetchShops({ pagination: { _disabled: true } })
      this.fetchReservations()
    },

    computed: {
      statuses() {
        return reduce(
          this.statusesObject,
          (res, locale, id) => {
            return [...res, { id, name: locale }]
          },
          []
        )
      },

      statusesObject() {
        return {
          reservation: this.$t("company_system.booking_confirmation.reservation_statuses.reservation"),
          reservation_change: this.$t("company_system.booking_confirmation.reservation_statuses.reservation_change"),
          cancel: this.$t("company_system.booking_confirmation.reservation_statuses.cancel"),
          no_show: this.$t("company_system.booking_confirmation.reservation_statuses.no_show")
        }
      }
    },

    methods: {
      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.debouncedFetchReservations()
      },

      changeFilters(filters) {
        this.setFilters(filters)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.debouncedFetchReservations()
      },

      changeSorting(sorting) {
        this.setSorting(sorting)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.debouncedFetchReservations()
      },

      selectReservation(reservation) {
        this.selectedReservation = reservation
        if (reservation === null) {
          this.fetchReservations()
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  .booking-confirmation-tab
    position: relative
</style>

<template lang="pug">
  .drop-off-fees-tab
    AppOverlayLoader(:state="dropOffFeesLoading")
    .drop-off-fee-details(v-if="isDropOffFeeFormShown")
      Form(
        :drop-off-fee-id="selectedDropOffFeeId"
        :prefectures="prefectures"
        :shops="shops"
        :car-types="carTypes"
        @back="hideDropOffFeeForm"
      )
    .drop-off-fees(v-else)
      BAlert(
        show
        variant="warning"
      ) {{ $t("company_system.basic_settings.drop_off_fees.warning_message") }}
      Filters(
        :prefectures-loading="prefecturesLoading"
        :prefectures="prefectures"
        :shops-loading="shopsLoading"
        :shops="shops"
        :car-types-loading="carTypesLoading"
        :car-types="carTypes"
        :filters="filters"
        @change-filters="changeFilters"
      )
      ActionBar(
        @add-new="showDropOffFeeForm"
        :with-download="false"
      )
      Table(
        :items="dropOffFees",
        :sorting-data="sortingData"
        @select-row="selectDropOffFee"
        @remove-row="removeDropOffFee"
        @sorting="changeSorting"
      )
</template>

<script>
  // misc
  import { appDebounce } from "@/helpers/common"

  // store modules
  import dropOffFeesModule from "@/config/store/company_system/basic_settings/drop_off_fees"
  import prefecturesModule from "@/config/store/prefectures"
  import carTypesModule from "@/config/store/company_system/car_class_settings/car_types"
  import shopsModule from "@/config/store/company_system/shops_settings/shops/basic_settings"

  // mixins
  import withSorting from "@/mixins/withSorting"
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"

  const dropOffFeesMixin = withStoreModule(dropOffFeesModule, {
    resetState: true,
    name: "dropOffFees",
    readers: {
      dropOffFees: "items",
      filters: "filters",
      dropOffFeesLoading: "loading",
      sortingData: "sorting"
    },
    actions: {
      fetchDropOffFees: "FETCH_ITEMS",
      deleteDropOffFee: "DELETE_ITEM"
    },
    mutations: {
      setFilters: "SET_FILTERS",
      setSorting: "SET_SORTING"
    }
  })

  const prefecturesMixin = withStoreModule(prefecturesModule, {
    name: "prefectures",
    readers: { prefectures: "items", prefecturesLoading: "loading" },
    actions: { fetchPrefectures: "FETCH_ITEMS" }
  })

  const carTypesMixin = withStoreModule(carTypesModule, {
    name: "companiesCarTypes",
    readers: { carTypes: "items", carTypesLoading: "loading" },
    actions: { fetchCarTypes: "FETCH_ITEMS" }
  })

  const shopsMixin = withStoreModule(shopsModule, {
    name: "companiesShops",
    readers: { shops: "items", shopsLoading: "loading" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  export default {
    components: {
      Filters: () => import("./Filters"),
      Table: () => import("./Table"),
      Form: () => import("./Form"),
      ActionBar: () => import("../../ActionBar"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [dropOffFeesMixin, prefecturesMixin, carTypesMixin, shopsMixin, withSorting, withConfirmation],

    data() {
      return {
        isDropOffFeeFormShown: false,
        selectedDropOffFeeId: null
      }
    },

    created() {
      this.debouncedFetchDropOffFees = appDebounce(() => this.fetchDropOffFees())
    },

    mounted() {
      this.fetchDropOffFees()
      this.fetchPrefectures()
      this.fetchShops()
      this.fetchCarTypes()
    },

    methods: {
      hideDropOffFeeForm() {
        this.isDropOffFeeFormShown = false
        this.selectedDropOffFeeId = null
        this.fetchDropOffFees()
      },

      showDropOffFeeForm() {
        this.isDropOffFeeFormShown = true
      },

      selectDropOffFee({ id }) {
        this.selectedDropOffFeeId = id
        this.isDropOffFeeFormShown = true
      },

      changeSorting(sorting) {
        this.setSorting(sorting)
        this.debouncedFetchDropOffFees()
      },

      changeFilters(filters) {
        this.setFilters(filters)
        this.debouncedFetchDropOffFees()
      },

      removeDropOffFee({ id }) {
        this.$confirm({
          title: this.$t("company_system.basic_settings.delete_drop_off_fee_confirmation"),
          resolve: {
            handler: () => {
              this.deleteDropOffFee(id)
            }
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  .drop-off-fees-tab
    position: relative
  .drop-off-fees
    .alert
      margin-top: 15px
</style>

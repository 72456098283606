<template lang="pug">
  .create-company-form
    AppOverlayLoader(:state="loading")
    .create-company-form-container
      .create-company-form-block
        .create-company-form-row
          .create-company-form-row-label
            FormFieldLabel(
              :title="$t('ota_management.company.form.name')"
              required
            )
          .create-company-form-field
            BFormInput(
              type="text"
              v-model="company.name"
              name="company-name"
              :state="$v.company.name.$error ? false : null"
              :placeholder="$t('ota_management.company.form.name')"
            )
            BFormInvalidFeedback(v-if="$v.company.name.$error")
              | {{ $t("ota_management.company.form.errors.please_enter") }}
        .create-company-form-row
          .create-company-form-row-label
            FormFieldLabel(
              :title="$t('ota_management.company.form.code')"
              required
            )
          .create-company-form-field
            BFormInput(
              type="text"
              v-model="company.code"
              name="company-name"
              :state="$v.company.code.$error ? false : null"
              :placeholder="$t('ota_management.company.form.code')"
            )
            BFormInvalidFeedback(v-if="$v.company.code.$error")
              | {{ $t("ota_management.company.form.errors.please_enter") }}
        .create-company-form-row
          .create-company-form-row-label
            FormFieldLabel(:title="$t('ota_management.company.form.ota')")
          .create-company-form-field
            AppDropdown(
              checkbox
              batch-select
              multiple
              allow-empty
              close-on-select
              :placeholder="$t('components.checkboxes_group.nothing_selected')"
              :value="selectedOtas"
              :items="availableOtas"
              @select="selectOtas"
            )
        .create-company-form-row
          .create-company-form-row-label
            FormFieldLabel(:title="$t('accounts.table.columns.organizations')")
          .create-company-form-field
            AppDropdown(
              checkbox
              batch-select
              multiple
              allow-empty
              searchable
              close-on-select
              :is-item-disabled="isOrganizationDisabled"
              :value="selectedOrganizations"
              :items="organizations"
              @select="selectOrganizations"
            )
        .create-company-form-row
          .create-company-form-row-label
            FormFieldLabel(
              :title="$t('ota_management.company_list.payn')"
            )
          .create-company-form-field
            AppCheckbox(
              v-model="company.payn"
            )
        .create-company-form-row
          .create-company-form-row-label
            FormFieldLabel(
              :title="$t('ota_management.company_list.company_system')"
            )
          .create-company-form-field
            AppCheckbox(
              v-model="company.company_system"
            )
        .create-company-form-row(
          :data-cy="$t('ota_management.company_list.auto_publish')"
        )
          .create-company-form-row-label
            FormFieldLabel(
              :title="$t('ota_management.company_list.auto_publish')"
            )
          .create-company-form-field
            AppToggle(
              :value="companyMarkupReleased"
              @change="setCompanyMarkupReleased"
            )
        .create-company-form-row(
          :data-cy="$t('ota_management.company_list.default_markup_fee')"
        )
          .create-company-form-row-label.fixed-align
            FormFieldLabel(
              :title="$t('ota_management.company_list.default_markup_fee')"
            )
          .create-company-form-field
            .d-flex
              MarkUpFee(
                :invalid="$v.company.markup.value.$error"
                :value="companyMarkup.value"
                :markup-type="companyMarkup.type"
                @change-markup-fee="changeCompanyMarkupFee"
              )
            BFormInvalidFeedback(
              v-if="!$v.company.markup.value.lessThanLimit"
              :state="$v.company.markup.value.$error ? false : null"
            )
              | {{ $t("ota_management.company.form.errors.markup_exceeded") }}
        .create-company-form-row.markup-limits(
          :class="{ 'disabled': isMarkupMaxValueDisabled }"
          :data-cy="$t('ota_management.company_list.markup_limits')"
        )
          .create-company-form-row-label.fixed-align
            FormFieldLabel(
              :title="$t('ota_management.company_list.markup_limits')"
            )
          .create-company-form-field
            .d-flex
              BFormInput.limit-input(
                :disabled="isMarkupMaxValueDisabled"
                type="number"
                number
                :state="$v.company.markup.max_value.$error ? false : null"
                :value="companyMarkup.max_value"
                min="0.0"
                max="999.0"
                step="0.01"
                @input="setMaxValue($event)"
              )
              .label
                | {{ markupMaxValueLabel }}
            BFormInvalidFeedback(
              v-if="!$v.company.markup.max_value.lessThanOtaMarkups"
              :state="$v.company.markup.max_value.$error ? false : null"
            )
              | {{ gapExceededErrorText }}
            .cannot-set-limit(v-if="isMarkupMaxValueDisabled")
              FaIcon.icon(icon="exclamation-triangle")
              | {{ $t("ota_management.company.form.errors.cannot_set_limit") }}
        .create-company-form-row
          .create-company-form-row-label
            FormFieldLabel(:title="$t('ota_management.company_list.not_subject_for_me_markup')")
          .create-company-form-field
            AppDropdown(
              checkbox
              batch-select
              multiple
              allow-empty
              :value="selectedSkippedOtas"
              :items="availableOtas"
              @select="selectSkippedOtas"
            )
        .create-company-form-row
          .create-company-form-row-label
            FormFieldLabel(
              :title="$t('ota_management.company_list.prevent_markup_set')"
            )
          .create-company-form-field
            AppCheckbox(
              :value="companyMarkup.skip_plan_markups"
              @change="changeSkipPlanMarkups"
            )
        .create-company-form-row.column
          .create-company-form-row-label
            FormFieldLabel(
              :title="$t('ota_management.company_list.memo')"
            )
          .create-company-form-field
            textarea(
              type="text"
              :rows="5"
              :maxlength="1024"
              v-model="company.memo"
            )
    .col
      .d-flex.justify-content-end
        AppSaveButton(
          title="actions.confirm"
          :disabled="loading"
          @save="saveCompany"
        )
</template>

<script>
  // misc
  import { PERCENT_MARKUP, FIXED_MARKUP } from "@/config/constants"
  import { mapGetters } from "vuex"
  import { filter, find, get, map, transform } from "lodash-es"
  import { OTAS_WITH_COMPANY_SYSTEM } from "@/config/constants"

  // store modules
  import companiesModule from "@/config/store/maestro/markups/companies"

  // mixins
  import withValidations from "@/mixins/withValidations"
  import withStoreModule from "@/mixins/withStoreModule"

  const validationsMixin = withValidations(({ required, minLength, minValue }) => ({
    company: {
      name: {
        required,
        minLength: minLength(1)
      },
      code: {
        required,
        minLength: minLength(1)
      },
      payn: { required },
      markup: {
        released: { required },
        value: {
          required,
          lessThanLimit(value) {
            if (this.isMarkupMaxValueDisabled) {
              return true
            } else {
              return this.markupMaxValue === null || value < this.markupMaxValue
            }
          },
          minValue: minValue(0)
        },
        max_value: {
          lessThanOtaMarkups() {
            if (this.isMarkupMaxValueDisabled) {
              return true
            } else {
              return this.gapExceededOtaIds.length === 0
            }
          }
        },
        type: { required }
      }
    }
  }))

  const companiesMixin = withStoreModule(companiesModule, {
    name: "maestroCompanies",
    readers: {
      loading: "loading",
      company: "item"
    },
    actions: {
      fetchCompany: "FETCH_ITEM",
      fetchCompanies: "FETCH_ITEMS",
      createCompany: "CREATE_ITEM",
      updateCompany: "UPDATE_ITEM"
    },
    mutations: {
      setCompany: "SET_ITEM"
    }
  })

  const defaultCompanyObject = () => ({
    name: "",
    code: "",
    ota_ids: [],
    organization_ids: [],
    payn: false,
    markup: {
      value: null,
      type: PERCENT_MARKUP.id,
      released: false,
      max_value: null,
      skipped_ota_ids: [],
      skip_plan_markups: false,
      value_by_ota: {}
    }
  })

  export default {
    components: {
      AppButton: () => import("@/components/elements/AppButton"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppIcon: () => import("@/components/elements/AppIcon"),
      MarkUpFee: () => import("../../../OtaManagement/CompanyList/MarkUpFee")
    },

    mixins: [companiesMixin, validationsMixin],

    props: {
      companyId: {
        type: Number,
        required: false
      },
      organizations: {
        type: Array,
        default: () => []
      },
      disabledOrganizationIds: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        markupTypes: [PERCENT_MARKUP, FIXED_MARKUP]
      }
    },

    mounted() {
      if (this.isEdit) {
        this.fetchCompany(this.companyId)
      } else {
        this.setCompany(defaultCompanyObject())
      }
    },

    computed: {
      ...mapGetters(["translatedOtaList"]),

      isEdit() {
        return !!this.companyId
      },

      availableOtas({ translatedOtaList }) {
        return translatedOtaList.filter(({ rawName }) => OTAS_WITH_COMPANY_SYSTEM.includes(rawName))
      },

      companyOtaIds() {
        return get(this.company, "ota_ids", [])
      },

      skippedOtaIds() {
        return get(this.company, "markup.skipped_ota_ids", [])
      },

      companyOrganizationIds() {
        return get(this.company, "organization_ids", [])
      },

      selectedOtas() {
        return filter(this.availableOtas, ({ id }) => this.companyOtaIds.includes(id))
      },

      selectedSkippedOtas() {
        return filter(this.availableOtas, ({ id }) => this.skippedOtaIds.includes(id))
      },

      selectedOrganizations() {
        return filter(this.organizations, ({ id }) => this.companyOrganizationIds.includes(id))
      },

      selectedMarkupType() {
        return find(this.markupTypes, ({ id }) => id === this.companyMarkup.type)
      },

      companyMarkup() {
        return get(this.company, "markup", {})
      },

      companyMarkupReleased() {
        return get(this.company, "markup.released", false)
      },

      markupMaxValue() {
        return get(this.company, "markup.max_value", null)
      },

      markupGap({ markupMaxValue }) {
        if (markupMaxValue === null) {
          return 0
        }

        return this.companyMarkup.value ? markupMaxValue - this.companyMarkup.value : markupMaxValue
      },

      gapExceededOtaIds({ markupMaxValue }) {
        if (this.isMarkupMaxValueDisabled || markupMaxValue === null) {
          return []
        }

        return transform(
          this.companyMarkup.value_by_ota,
          (arr, value, key) => {
            const otaId = Number(key)
            if (this.companyOtaIds.includes(otaId) && value > this.markupGap) {
              arr.push(otaId)
            }

            return arr
          },
          []
        )
      },

      gapExceededErrorText({ translatedOtaList, gapExceededOtaIds }) {
        const otaNames = map(
          filter(translatedOtaList, ({ id }) => gapExceededOtaIds.includes(id)),
          "name"
        ).join(", ")
        return this.$t("ota_management.company.form.errors.otas_markup_exceeded", { ota_names: otaNames })
      },

      markupMaxValueType() {
        return get(this.company, "markup.max_value_type", null)
      },

      markupMaxValueLabel() {
        return this.selectedMarkupType?.name
      },

      isMarkupMaxValueDisabled() {
        return this.isEdit && this.markupMaxValueType !== this.companyMarkup.type
      }
    },

    methods: {
      setCompanyMarkupReleased({ value }) {
        this.company.markup.released = value
      },

      selectOtas(otas) {
        this.company.ota_ids = map(otas, "id")
      },

      selectSkippedOtas(otas) {
        this.company.markup.skipped_ota_ids = map(otas, "id")
      },

      setMaxValue(value) {
        this.company.markup.max_value = value === "" ? null : Number(value)
      },

      changeSkipPlanMarkups(value) {
        this.company.markup.skip_plan_markups = value
      },

      isOrganizationDisabled({ id }) {
        return this.disabledOrganizationIds.includes(id)
      },

      selectOrganizations(organizations) {
        this.company.organization_ids = map(organizations, "id")
      },

      changeCompanyMarkupFee(fee) {
        this.company.markup = { ...this.company.markup, ...fee }
      },

      saveCompany() {
        this.validateAttributes()
        if (!this.isValid) {
          return
        }
        this.sendRequest().then(() => {
          this.$emit("close")
          this.fetchCompanies()
        })
      },

      async sendRequest() {
        if (this.isEdit) {
          await this.updateCompany(this.company)
        } else {
          await this.createCompany(this.company)
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .create-company
    margin-top: 50px
    color: $default-black

    .col-xxl-6
      @media (min-width: 1599px)
        flex: 0 0 50%
        max-width: 50%

    &.loading
      opacity: 0.7
      cursor: not-allowed

    &-form
      position: relative

      &-container
        display: flex
        gap: 20px

      &-row
        align-items: center
        display: flex
        margin: 0 0 20px 0
        width: 100%

        &-label
          width: 170px

          &.fixed-align
            align-self: start
            margin-top: 6px

        &.disabled
          color: $default-gray-medium

        span
          font-size: 0.8rem

        &.markup-limits
          ::v-deep
            input
              width: 178px

        &.column
          flex-direction: column
          align-items: start

          .create-company-form-field
            padding: 0
            width: 100%

            textarea
              width: 100%

      &-field
        +styled-inputs
        padding: 0 6px
        width: 61%

        .label
          padding: 6px 10px

        .app-checkbox,
        .app-select
          padding: 6px 0

        .invalid-feedback
          font-size: 11px

        .cannot-set-limit
          font-size: 11px
          color: $default-gray-medium

          .icon
            margin-left: 3px
            margin-right: 5px

        input
          text-align: left
          height: 34px
          transition: all 0.1s linear
          border-color: $border-element-color
          font-size: 0.8rem

          &:disabled
            background-color: $default-white
            cursor: not-allowed
            opacity: 0.6

          &.limit-input
            text-align: right

          &:focus
            background: $default-gray-light

          &.is-invalid
            border-color: $default-red
</style>

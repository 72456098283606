<template lang="pug">
  .basic-settings
    .search
      TopBar(
        :shops-items="shopsItems"
        :car-classes-items="carClassesItems"
        :shops-loading="shopsLoading"
        :car-classes-loading="carClassesLoading"
        :filters="filters"
        :disabled-save="!hasUnsavedChanges"
        :disabled-select="noAvailableOta"
        @select-shop="handleSelectShop"
        @select-car-class="handleSelectCarClass"
        @change-filters="applyFilters"
        @save="handleSave"
      )
    .no-ota(v-if="noAvailableOta")
      FaIcon.icon(icon="exclamation-triangle")
      | {{ $t("ota.no_available_otas") }}
    .bottom
      .tabs
        AppOverlayLoader(:state="commonLoading || loading")
        Tabs(
          :otas="planSettingsOtas"
          :value="selectedOta"
          :disabled="noPlanSelected"
          :validator="$v"
          :is-dirty="isDirty"
          @change="handleOtaChange"
        )
</template>

<script>
  // store modules
  import { mapGetters } from "vuex"
  import shopMatchingModule from "@/config/store/matching/shop"
  import pricesCarClassesModule from "@/config/store/price_management/car_class"
  import basicSettingsModule from "@/config/store/price_management/plan_settings/basic_settings"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withModal from "@/mixins/withModal"
  import withScrollTop from "@/mixins/withScrollTop"
  import withConfirmation from "@/mixins/withConfirmation"

  //validation
  import withOtaValidations from "./Validations"

  // modal
  import SaveModal from "./SaveModal"

  // misc
  import { SERIALIZER_VIEW_FILTERS } from "@/config/constants"
  import "vue-slider-component/theme/default.css"
  import { isNumber, find, get, isNil, isEqual, isEmpty, filter } from "lodash-es"

  const shopMatchingMixin = withStoreModule(shopMatchingModule, {
    resetState: true,
    name: "basicSettingsShopMatching",
    readers: { shopsLoading: "loading", shopsItems: "items" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const pricesCarClassesMixin = withStoreModule(pricesCarClassesModule, {
    resetState: true,
    name: "basicSettingsPricesCarClasses",
    readers: { carClassesLoading: "loading", carClassesItems: "items" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  const basicSettingsPaginationMixin = withStoreModule(basicSettingsModule, {
    resetState: true,
    name: "paginatedTemplates",
    mutations: {
      changeOtaForPT: "CHANGE_OTA"
    }
  })

  const basicSettingsMixin = withStoreModule(basicSettingsModule, {
    resetState: true,
    name: "basicSettings",
    readers: ["plan", "loading", "filters", "isDirty", "templates", "template"],
    mutations: {
      setPlan: "SET_PLAN",
      setTemplate: "SET_TEMPLATE",
      changeOta: "CHANGE_OTA",
      setMessages: "SET_MESSAGES",
      setFilters: "SET_FILTERS",
      setDirty: "SET_DIRTY"
    },
    actions: {
      fetchTemplates: "FETCH_TEMPLATES",
      fetchPlan: "FETCH_PLAN",
      createPlan: "CREATE_PLAN",
      updatePlan: "UPDATE_PLAN"
    }
  })

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      TopBar: () => import("./TopBar"),
      Tabs: () => import("./Tabs"),
      JalanSettings: () => import("./Settings/Jalan"),
      RakutenSettings: () => import("./Settings/Rakuten"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [
      basicSettingsPaginationMixin,
      pricesCarClassesMixin,
      shopMatchingMixin,
      basicSettingsMixin,
      withOtaValidations,
      withConfirmation,
      withModal,
      withScrollTop
    ],

    beforeRouteLeave(to, _from, next) {
      this.beforeRouteLeaveHandler({ to, next, isChanges: this.hasUnsavedChanges })
    },

    data() {
      return {
        customLoading: false
      }
    },

    async mounted() {
      this.$store.commit("RESET_ORGANIZATION_OTA_LIST")
      await this.$store.dispatch("FETCH_ORGANIZATION_OTA_LIST")

      if (!this.noAvailableOta) {
        this.setFilters({ ...this.filters, ota: this.availableOtas[0] })

        if (isEmpty(this.$route.query)) {
          this.fetchShopsAction()
          this.fetchTemplatesAction()
        } else {
          this.loadFromQuery(this.$route.query)
        }
      }
    },

    watch: {
      $route: {
        handler({ query }) {
          if (!isEmpty(query)) {
            this.loadFromQuery(query)
          }
        },
        deep: true
      },

      $data: {
        handler() {
          this.setMessages([])
        },
        deep: true
      },

      hasUnsavedChanges(useConfirm) {
        this.setDirty(useConfirm)
        this.setLogoutConfirm(useConfirm)
      }
    },

    computed: {
      ...mapGetters(["planSettingsOtas"]),

      shouldShowStatus() {
        return !!this.currentPlan.id
      },

      commonLoading() {
        return this.customLoading || this.loading || this.shopsLoading || this.carClassesLoading
      },

      noPlanSelected() {
        return !isNumber(this.filters.shop_id) || !isNumber(this.filters.car_class_id)
      },

      noAvailableOta() {
        return isEmpty(this.availableOtas)
      },

      availableOtas() {
        return filter(this.planSettingsOtas, ota => ota.active && ota.plan_settings_allowed)
      },

      selectedOta({ filters: { ota } }) {
        return find(this.planSettingsOtas, { id: ota?.id }) || this.availableOtas[0] || {}
      },

      selectedOtaId() {
        return this.selectedOta?.id
      },

      isNewPlan() {
        return isNil(get(this.currentPlan, "id"))
      },

      hasUnsavedChanges({ currentPlan, plan }) {
        return !isEmpty(plan) && !isEqual(currentPlan, plan)
      }
    },

    methods: {
      isEmpty,

      async loadFromQuery({ shop_id, car_class_id, ota_id }) {
        this.customLoading = true

        if (this.selectedOta.id !== Number(ota_id) || isEmpty(this.shopsItems)) {
          const ota = find(this.planSettingsOtas, { id: Number(ota_id) })
          await this.handleOtaChange(ota)
        }
        if (this.filters.shop_id === Number(shop_id)) {
          const carClass = find(this.carClassesItems, { id: Number(car_class_id) })
          this.handleSelectCarClass(carClass)
        } else {
          const shop = find(this.shopsItems, { id: Number(shop_id) })
          this.handleSelectShop(shop, Number(car_class_id))
        }

        this.customLoading = false
      },

      updateUrl() {
        const query = {
          ota_id: this.selectedOta.id,
          shop_id: this.filters.shop_id,
          car_class_id: this.filters.car_class_id
        }

        this.$router.replace({ query }).catch(() => {})
      },

      applyFilters(filters) {
        this.withConfirmAction(() => {
          this.setFilters(filters)
          this.setMessages([])
        })
      },

      async handleOtaChange(ota) {
        this.changeOta(ota)
        this.changeOtaForPT(ota)
        this.fetchTemplatesAction()
        await this.fetchShopsAction()
      },

      async handleSave() {
        this.validate()

        if (this.isValid) {
          await this.executeAction()
          this.resetValidation()

          this.$openModal({
            component: SaveModal,
            hideHeader: true,
            headerLine: false,
            closeOnEsc: false,
            closeOnClick: false,
            props: {
              templates: this.templates,
              template: this.template
            }
          })
        } else {
          this.$nextTick(() => {
            this.scrollTo({ target: ".invalid-section", block: "center" })
          })
        }
      },

      async executeAction() {
        if (this.isNewPlan) {
          await this.createPlan(this.currentPlan)
        } else {
          await this.updatePlan(this.currentPlan)
        }
      },

      handleSelectShop({ id: shop_id }, preselectId = null) {
        this.withConfirmAction(() => {
          this.setTemplate({})
          this.setPlan({})

          this.setFilters({ ...this.filters, shop_id, car_class_id: null, all_shops: false, all_car_classes: false })
          this.setMessages([])

          this.fetchCarClasses({
            shop_id,
            ota_id: this.selectedOtaId,
            serializer_view: shop_id && SERIALIZER_VIEW_FILTERS
          }).then(() => {
            if (preselectId) {
              const car_class = find(this.carClassesItems, { id: preselectId })
              this.handleSelectCarClass(car_class)
            }
          })
        })
      },

      handleSelectCarClass({ id: car_class_id, insurance }) {
        this.withConfirmAction(() => {
          this.setFilters({ ...this.filters, car_class_id, insurance, all_shops: false, all_car_classes: false })
          this.setMessages([])
          this.setTemplate({})
          if (Number(this.$route.query.car_class_id) === car_class_id) {
            this.fetchPlan()
          } else {
            this.updateUrl()
          }
        })
      },

      fetchShopsAction() {
        return this.fetchShops({
          pagination: { _disabled: true },
          matched_only: true,
          synced_only: true,
          active_only: true,
          ota_id: this.selectedOtaId
        })
      },

      fetchTemplatesAction() {
        return this.fetchTemplates({ pagination: { _disabled: true } })
      },

      withConfirmAction(callback) {
        this.$conditionalConfirm({
          useConfirm: this.hasUnsavedChanges,
          handler: callback
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .basic-settings
    margin-top: 20px

    .bottom
      margin-top: 20px
      display: flex
      width: 100%

      .tabs
        position: relative
        width: 100%

    .no-ota
      width: 100%
      display: flex
      justify-content: center
      margin-top: 200px
      position: absolute
      color: $default-gray
      font-size: 0.8rem

      .icon
        font-size: 1rem
        margin-right: 10px
</style>

<template lang="pug">
  .plan-status
    .label
      FormFieldLabel.label(:title="$t('ota_management.plan_list.form.publish_settings.current_status')")
    .field
      .label
        span {{ statusLabel }}
      .status-tooltip
        AppTooltip.status-tooltip-icon(
          v-if="isAppTooltipShown"
          useAppIcon
          placement="right"
          :title="appTooltipText"
          icon="exclamationCircleAlt"
        )
        template(v-if="isOtaTooltipShown")
          OtaTooltip(
            :otas="otas"
            icon-color="purple"
            :published-by-ota="publishedByOta"
          )
</template>

<script>
  const STATUS_PREPARING_BY_OTA = "preparing_by_ota"
  const STATUS_PAUSED = "paused"
  const STATUS_PUBLISHED = "published"

  // misc
  import { some, values } from "lodash-es"

  export default {
    components: {
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      OtaTooltip: () => import("./OtaTooltip"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel")
    },

    props: {
      status: {
        type: String,
        required: false
      },

      otas: {
        type: Array,
        default: () => new Array()
      },

      publishedByOta: {
        type: Object,
        default: () => new Object()
      }
    },

    computed: {
      isAppTooltipShown() {
        return this.appToolTipStatusList.includes(this.status)
      },

      isOtaTooltipShown() {
        return some(values(this.publishedByOta), published => !published) && this.statusPublished
      },

      statusPublished() {
        return this.status === STATUS_PUBLISHED
      },

      appTooltipText() {
        return this.$t(`ota_management.plan_list.status.tooltip.${this.status}`)
      },

      appToolTipStatusList() {
        return [STATUS_PREPARING_BY_OTA, STATUS_PAUSED]
      },

      statusLabel({ status }) {
        return status && this.$t(`ota_management.plan_list.status.${status}`)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/company-system.sass"
  @import "@/assets/styles/variables.sass"

  .plan-status
    .label
      +form-label

    .field
      display: flex
      flex-direction: row

      .label
        margin-top: 10px
        margin-left: 10px
        font-weight: 400
        font-size: 16px

      .status-tooltip
        &-icon
          &.app-icon-wrapper
            margin-top: 9px
            ::v-deep
              svg
                height: 20px
                width: 20px
</style>

<template lang="pug">
  .time-division-tab
    AppOverlayLoader(:state="timeSlotsLoading")
    .time-division-details(v-if="isTimeSlotFormShown")
      Form(
        :time-slot-id="selectedTimeSlotId"
        @back="hideTimeSlotForm"
      )
    .time-divisions(v-else)
      ActionBar(
        @add-new="showTimeSlotForm"
        :with-download="false"
      )
      .time-divisions-table
        table
          thead
            tr
              th.time-division-code.sortable(@click="handleSorting('code')")
                span {{ $t("company_system.basic_settings.time_division.code") }}
                FaIcon.icon(:icon="sortingIcon('code')")
              th.time-division-name.sortable(@click="handleSorting('name')")
                span {{ $t("company_system.basic_settings.time_division.name") }}
                FaIcon.icon(:icon="sortingIcon('name')")
              th.last-updated.sortable(@click="handleSorting('updated_at')")
                span {{ $t("company_system.basic_settings.time_division.last_updated") }}
                FaIcon.icon(:icon="sortingIcon('updated_at')")
              th.actions
          tbody
            template(v-for="timeSlot in timeSlots")
              tr(
                @click="selectTimeSlot(timeSlot)"
              )
                td {{ timeSlot.code }}
                td {{ timeSlot.name }}
                td {{ timeSlot.updated_at }}
                td.remove-action
                  AppIconButton.action-button(
                    :disabled="timeSlot.unremovable"
                    useAppIcon
                    icon="trash"
                    @click.stop="removeTimeSlot(timeSlot)"
                  )
      AppPagination(
        :current-page="pagination.current_page"
        :total="pagination.total_count"
        :per-page="pagination.per_page"
        @change-pagination-data="changePagination"
      )
</template>

<script>
  // misc
  import { appDebounce } from "@/helpers/common"
  import { switchSorting, sortingIcon } from "@/helpers/matching"

  // store modules
  import timeSlotsModule from "@/config/store/company_system/basic_settings/time_slots"

  // mixins
  import withSorting from "@/mixins/withSorting"
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"

  const timeSlotsMixin = withStoreModule(timeSlotsModule, {
    name: "timeSlots",
    readers: {
      timeSlots: "items",
      timeSlotsLoading: "loading",
      sortingData: "sorting",
      pagination: "pagination"
    },
    actions: {
      fetchTimeSlots: "FETCH_ITEMS",
      deleteTimeSlot: "DELETE_ITEM"
    },
    mutations: {
      setPagination: "SET_PAGINATION_DATA",
      setFilters: "SET_FILTERS",
      setSorting: "SET_SORTING"
    }
  })

  export default {
    components: {
      Form: () => import("./Form"),
      ActionBar: () => import("../../ActionBar"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [withSorting, timeSlotsMixin, withConfirmation],

    data() {
      return {
        isTimeSlotFormShown: false,
        selectedTimeSlotId: null
      }
    },

    created() {
      this.debouncedFetchTimeSlots = appDebounce(() => this.fetchTimeSlots())
    },

    mounted() {
      this.fetchTimeSlots()
    },

    methods: {
      hideTimeSlotForm() {
        this.isTimeSlotFormShown = false
        this.selectedTimeSlotId = null
        this.fetchTimeSlots()
      },

      showTimeSlotForm() {
        this.isTimeSlotFormShown = true
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.debouncedFetchTimeSlots()
      },

      selectTimeSlot({ id }) {
        this.selectedTimeSlotId = id
        this.isTimeSlotFormShown = true
      },

      removeTimeSlot({ id }) {
        this.$confirm({
          title: this.$t("company_system.basic_settings.delete_time_slot_confirmation"),
          resolve: {
            handler: () => {
              this.deleteTimeSlot(id)
            }
          }
        })
      },

      handleSorting(newField) {
        this.changeSorting(switchSorting({ ...this.sortingData, newField }))
      },

      sortingIcon(field) {
        return sortingIcon(this.sortingData, field)
      },

      changeSorting(sorting) {
        this.setSorting(sorting)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.debouncedFetchTimeSlots()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .time-division-tab
    position: relative
  .time-divisions
    &-table
      +listing-container(21px)
      margin-top: 20px
      margin-left: 10px

      table
        +custom-table
        +matchings-table-header-sticky

        table-layout: fixed
        overflow-wrap: break-word

        thead
          th
            max-width: none !important
            margin: 0

            &.time-division-name
              width: 64%

            &.last-updated,
            &.time-division-code
              width: 16%

            &.actions
              width: 4%
        tbody
          tr
            cursor: pointer

            td
              &.remove-action
                color: $default-purple
                text-align: center

                .action-button
                  +icon-button($default-purple)
</style>
